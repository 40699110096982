
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './screens/Layout'
import Home from './screens/Home'
import Impressum from './screens/Impressum'
import Dpg from './screens/Dpg'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="dpg" element={<Dpg />} />
          <Route path="impressum" element={<Impressum />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
