import React from "react";
import { Link } from "react-router-dom";

const Impressum = ({ navigation, route }) => {
  return (
    <>
      <h2>Impressum</h2>
      <p>
        <Link to="/">back</Link>
      </p>

      <h3>Provider:</h3>
      <p>
        Amin Abromand
        <br />
        Vogelrohrsheide 124a
        <br />
        48167 Münster
      </p>

      <h3>Contact:</h3>
      <p>
        Phone: 0176/23563555
        <br />
        Email: <a href="mailto:info@abromand.com">info@abromand.com</a>
        <br />
      </p>

      <h3>For editorial content:</h3>
      <p>
        Responsible according to § 55 Abs.2 RStV
        <br />
        Amin Abromand
        <br />
        Vogelrohrsheide 124a
        <br />
        48167 Münster
      </p>
    </>
  );
};

export default Impressum;