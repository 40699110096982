import React from "react";
import { Link } from "react-router-dom";

const Dpg = ({ navigation, route }) => {
  return (
    <>
      <h2>Data Protection Guidelines</h2>
      <p>
        <Link to="/">back</Link>
      </p>

      <p>
        This privacy policy will explain how our organization uses the personal
        data we collect from you when you use our website and mobile
        application.
      </p>
      <h3>Topics:</h3>
      <ul>
        <li>What data do we collect?</li>
        <li>How do we collect your data?</li>
        <li>How will we use your data?</li>
        <li>How do we store your data?</li>
        <li>Marketing</li>
        <li>What are your data protection rights?</li>
        <li>What are cookies?</li>
        <li>How do we use cookies?</li>
        <li>What types of cookies do we use?</li>
        <li>How to manage your cookies</li>
        <li>Privacy policies of other websites</li>
        <li>Changes to our privacy policy</li>
        <li>How to contact us</li>
        <li>How to contact the appropriate authorities</li>
      </ul>
      <h3>What data do we collect?</h3>
      <p>Our Company collects the following data:</p>
      <ul>
        <li>We collect no data at all.</li>
        <li>All data that you provide is stored on your phone.</li>
      </ul>
      <h3>How do we collect your data?</h3>
      <p>We do not collect any of your data.</p>
      <h3>How will we use your data?</h3>
      <p>As we do not have your data, we do not use it.</p>
      <h3>How do we store your data?</h3>
      <p>Your data is stored on your phone only.</p>
      <h3>Marketing</h3>
      <p>We do not run marketing campaigns</p>
      <h3>Cookies</h3>
      <p>We do not use cookies</p>
      <h3>Privacy policies of other websites</h3>
      <p>
        Our privacy policy applies only to our website, so if you click on a
        link to another website, you should read their privacy policy.
      </p>
      <h3>Changes to our Data Proteciton Guidelines</h3>
      <p>
        Our Company keeps its privacy policy under regular review and places any
        updates on this web page. This privacy policy was last updated on 24
        March 2023.
      </p>
      <h3>How to contact us</h3>
      <p>
        If you have any questions about Our Company’s privacy policy, the data
        we hold on you, or you would like to exercise one of your data
        protection rights, please do not hesitate to contact us.
      </p>
      <p>
        Email us at: <a href="mailto:info@abromand.com">info@abromand.com</a>
      </p>
    </>
  );
};

export default Dpg;