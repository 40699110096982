import React from "react";
import { Link } from "react-router-dom";

const Home = ({ navigation, route }) => {
  return (
    <>
      <h2>Homepage</h2>
      <p>
        <Link to="/dpg">Data Protection Guidelines</Link>
      </p>
      <p>
        <Link to="/impressum">Impressum</Link>
      </p>
    </>
  );
};

export default Home;
